import React, { Suspense, useState } from "react";
import { Link } from "react-router-dom";

import { LinkedIn, YouTube } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";
import { MiscService } from "@src/rest";

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

const Footer = () => {

  // const grid2 = [
  // { title: "Contact Us", link: "/contact-us" },
  // { title: "About Us", link: "/about-us" },
  // { title: "Terms of Service", link: "/terms" }
  // ];

  const [msg, setMsg] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState('');

  function saveEmail(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // @ts-ignore

    setMsg(undefined)
    MiscService.subscribeUpdates({ email }).then(response => {
      setMsg(response.message)
      setEmail("")
    }, err => {
      console.log(err)
    })
  }

  return <React.Fragment>

    <Suspense fallback={Loader()}>
      <footer className="footer">
        <Container>
          <Grid container alignItems='center' sx={{ py: 4 }}>
            <Grid item lg={4}>
              <Link to="#" className="logo-footer">
                <img src="/images/logo-light.png" height="44" alt=""/>
              </Link>
            </Grid>

            <Grid item lg={4} md={4} xs={1}>
            </Grid>

            <Grid item lg={4} md={4}>
              <Grid container spacing={2} sx={{  justifyContent: 'flex-end' }}>
                <Grid item>
                  <Link to="https://youtube.com/@devicehub_ai" target='_blank'>
                    <YouTube
                      style={{
                        fontSize: '2rem',
                        color: '#fff',
                        border: '2px solid #fff',
                        borderRadius: '50%',
                        padding: '4px',
                      }}
                    />
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="https://linkedin.com/company/devicehub-ai/" target='_blank'>
                    <LinkedIn
                      style={{
                        fontSize: '2rem',
                        color: '#fff',
                        border: '2px solid #fff',
                        borderRadius: '50%',
                        padding: '4px',
                      }}
                    />
                  </Link>
                </Grid>
              </Grid>

              {/*<p className="pt-1">*/}
              {/*  Get latest updates*/}
              {/*</p>*/}
              {/*<div className="subcribe-form">*/}
              {/*  <form onSubmit={(event) => saveEmail(event)}>*/}
              {/*    <div className="mb-0">*/}
              {/*      <input*/}
              {/*        type="email"*/}
              {/*        className={*/}
              {/*          "border bg-white rounded-pill"*/}
              {/*        }*/}
              {/*        placeholder="Enter your email:"*/}
              {/*        required*/}
              {/*        value={email}*/}
              {/*        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {*/}
              {/*          setEmail(event.target.value);*/}
              {/*        }}*/}
              {/*      />*/}
              {/*      <button type="submit" className="btn btn-pills btn-success">*/}
              {/*        <NavigateNext className="  icon-sm  "*/}
              {/*        />*/}
              {/*      </button>*/}
              {/*    </div>*/}

              {/*    <p className="">*/}
              {/*      {msg}*/}
              {/*    </p>*/}
              {/*  </form>*/}
              {/*</div>*/}
            </Grid>
          </Grid>

        </Container>

        <div className="footer-py-3 footer-bar">
          <Container className="text-center">
            <Grid container className="d-flex justify-content-center">
              <Grid item sm={6}>
                <div className="align-items-center">
                  <p className="mb-0 p-1 small"
                     style={{ color: "#597999", fontSize: "10px" }}>© {(new Date().getFullYear())} Devicehub.ai |
                    All Rights Reserved | <Link style={{ color: "#597999" }} to="/terms">Terms of Service</Link></p>

                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </footer>

      {/*<BackToTop/>*/}

    </Suspense>
  </React.Fragment>
}

export default Footer;
