/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Alert } from '../models/Alert';
import type { ResponseMessage } from '../models/ResponseMessage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AlertsService {
    /**
     * Test alert
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Alert
     * @throws ApiError
     */
    public static getAlertTest(
        alertId: string,
        authorization: string,
    ): CancelablePromise<{
        rows?: any[];
        email?: string;
        query?: string;
        dedup_key?: string;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/alert/{alert_id}/test',
            path: {
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Get alerts
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId Suborg Id
     * @returns Alert Job
     * @throws ApiError
     */
    public static getAlerts(
        orgId: string,
        authorization: string,
        subOrgId?: string,
    ): CancelablePromise<Array<Alert>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/alert',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Create alert
     * @param orgId Org Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param subOrgId Suborg Id
     * @param requestBody
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static postAlert(
        orgId: string,
        authorization: string,
        subOrgId?: string,
        requestBody?: Alert,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/alert',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get alert
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Alert Alert
     * @throws ApiError
     */
    public static getAlert(
        alertId: string,
        authorization: string,
    ): CancelablePromise<Alert> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/alert/{alert_id}',
            path: {
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Update alert
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static putAlert(
        alertId: string,
        authorization: string,
        requestBody?: Alert,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/alert/{alert_id}',
            path: {
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete alert
     * @param alertId Alert Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns ResponseMessage Alert
     * @throws ApiError
     */
    public static deleteAlert(
        alertId: string,
        authorization: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/alert/{alert_id}',
            path: {
                'alert_id': alertId,
            },
            headers: {
                'Authorization': authorization,
            },
        });
    }
}
