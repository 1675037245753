import 'react-chat-widget/lib/styles.css';
import "./Apps.scss";
import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PublicLayout from './layout/PublicLayout';
import DashLayout from "./layout/DashLayout";
import secureLocalStorage from "react-secure-storage";
import { Authorization } from "./Defines";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";

import { GoogleOAuthProvider } from "@react-oauth/google";
import ReactGA from "react-ga4";
import { prod } from "@src/Defines";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { md5 } from '@mui/x-license-pro/encoding/md5';
import { LicenseInfo } from '@mui/x-license-pro';
import { LICENSE_SCOPES } from '@mui/x-license-pro/utils/licenseScope';
import { LICENSING_MODELS } from '@mui/x-license-pro/utils/licensingModel';
import { ConfirmAlertProvider } from 'material-confirm-alert';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Colors,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  RadialLinearScale,
  TimeScale,
  Title,
  Tooltip
} from "chart.js";
import 'chartjs-adapter-moment';

let orderNumber = '';
let expiryTimestamp = Date.now(); // Expiry is based on when the package was created, ignored if perpetual license
let scope = LICENSE_SCOPES[1]; // 'pro' or 'premium'
let licensingModel = LICENSING_MODELS[0]; // 'perpetual', 'subscription'
let licenseInfo = `O=${orderNumber},E=${expiryTimestamp},S=${scope},LM=${licensingModel},KV=2`;

LicenseInfo.setLicenseKey(md5(btoa(licenseInfo)) + btoa(licenseInfo));
ReactGA.initialize(prod() ? "G-ZK3X4BM5QJ" : "G-K35MZ6JS5X");


document.addEventListener("paste", function (e) {
  // @ts-ignore
  if (e.target.isContentEditable) {
    e.preventDefault();
    // @ts-ignore
    let clipboardData = e.clipboardData || window.clipboardData;
    // @ts-ignore
    let plainText = clipboardData.getData('Text');
    document.execCommand('insertText', false, plainText);
  }
});


export function RequireAuth({ children }: any) {
  const token = secureLocalStorage.getItem(Authorization)
  if (!token) {
    return <Navigate to={"/login?to=" + window.location.pathname} replace/>;
  }
  return children;
}

const theme = createTheme({
  typography: {
    fontFamily: 'Raleway, Arial',
  },
  palette: {
    primary: {
      light: '#112d4e',
      main: '#112d4e',
      dark: '#112d4e',
      contrastText: '#fff',
    },
    secondary: {
      light: '#19bfb7',
      main: '#19bfb7',
      dark: '#19bfb7',
      contrastText: '#000',
    },
    warning: {
      light: '#da594e',
      main: '#DB4437',
      dark: '#d73123',
      contrastText: '#fff',
    },
    info: {
      light: '#6c9dff',
      main: '#5890FF',
      dark: '#4382fc',
      contrastText: '#fff',
    },
  },
});

const GOOGLE_CLIENT_ID = "920335688762-b5108arle8oqh3h11babj3ialnrm3r9e.apps.googleusercontent.com"

Bugsnag.start({
  apiKey: '9bfcdb7052bbca81b27097beee0a7912',
  plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: '9bfcdb7052bbca81b27097beee0a7912' })
const ErrorBoundary = Bugsnag.getPlugin('react')!!.createErrorBoundary(React)

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors,
  RadialLinearScale,
  TimeScale,
  ArcElement,
  PointElement,
  LineElement,
);


const App = () => <ErrorBoundary>
  <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
    <ThemeProvider theme={theme}>
      <ConfirmAlertProvider>
        <BrowserRouter>

          <Routes>

            <Route path="dash/*" element={
              <RequireAuth>
                <DashLayout/>
              </RequireAuth>
            }/>

            <Route path="/*" element={
              <PublicLayout/>
            }/>

          </Routes>
        </BrowserRouter>
      </ConfirmAlertProvider>
    </ThemeProvider>
  </GoogleOAuthProvider>
</ErrorBoundary>

export default App;
