/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingConfiguration } from '../models/BillingConfiguration';
import type { Bucket } from '../models/Bucket';
import type { Feature } from '../models/Feature';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PublicService {
    /**
     * Get config
     * Returns config
     * @returns BillingConfiguration Successful operation
     * @throws ApiError
     */
    public static getPublicConfig(): CancelablePromise<BillingConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/config',
        });
    }
    /**
     * List all features
     * Returns a list of all features
     * @returns Feature Successful operation
     * @throws ApiError
     */
    public static getPublicFeatures(): CancelablePromise<Array<Feature>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/features',
        });
    }
    /**
     * List all buckets
     * Returns a list of all buckets
     * @returns Bucket Successful operation
     * @throws ApiError
     */
    public static getPublicBuckets(): CancelablePromise<Array<Bucket>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/public/buckets',
        });
    }
}
