/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BillingConfiguration } from '../models/BillingConfiguration';
import type { Bucket } from '../models/Bucket';
import type { Feature } from '../models/Feature';
import type { MessageResponse } from '../models/MessageResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BillingService {
    /**
     * Get billing config
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns BillingConfiguration Update successful
     * @throws ApiError
     */
    public static getBillingConfig(
        authorization: string,
    ): CancelablePromise<BillingConfiguration> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/config',
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Update billing config
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns MessageResponse Update successful
     * @throws ApiError
     */
    public static putBillingConfig(
        authorization: string,
        requestBody?: BillingConfiguration,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/billing/config',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create a feature for billing
     * Creates a new billable feature
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static postBillingFeatures(
        authorization: string,
        requestBody?: Feature,
    ): CancelablePromise<{
        message?: string;
        id?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/features',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid input, feature already exists`,
            },
        });
    }
    /**
     * List all features
     * Returns a list of all features
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Feature Successful operation
     * @throws ApiError
     */
    public static getBillingFeatures(
        authorization: string,
    ): CancelablePromise<Array<Feature>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/features',
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Update a feature
     * Updates an existing feature with the provided ID
     * @param id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Successful operation
     * @throws ApiError
     */
    public static putBillingFeatures(
        id: string,
        authorization: string,
        requestBody?: Feature,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/billing/features/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Feature not found`,
            },
        });
    }
    /**
     * Delete a feature
     * Deletes a feature with the provided ID
     * @param id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns any Successful operation
     * @throws ApiError
     */
    public static deleteBillingFeatures(
        id: string,
        authorization: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/billing/features/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `Feature not found`,
            },
        });
    }
    /**
     * Create a new bucket
     * Creates a new bucket with given features
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns any Bucket created
     * @throws ApiError
     */
    public static postBillingBuckets(
        authorization: string,
        requestBody?: Bucket,
    ): CancelablePromise<{
        message?: string;
        id?: string;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/billing/buckets',
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request. Invalid request body or parameters`,
            },
        });
    }
    /**
     * List all buckets
     * Returns a list of all buckets
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns Bucket Successful operation
     * @throws ApiError
     */
    public static getBillingBuckets(
        authorization: string,
    ): CancelablePromise<Array<Bucket>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/billing/buckets',
            headers: {
                'Authorization': authorization,
            },
        });
    }
    /**
     * Update an existing bucket
     * Updates a bucket with given data
     * @param id ID of the bucket to update
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @returns MessageResponse Bucket updated
     * @throws ApiError
     */
    public static putBillingBuckets(
        id: string,
        authorization: string,
        requestBody?: Bucket,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/billing/buckets/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a bucket
     * Deletes a bucket by given ID
     * @param id ID of the bucket to delete
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @returns MessageResponse Bucket deleted
     * @throws ApiError
     */
    public static deleteBillingBuckets(
        id: string,
        authorization: string,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/billing/buckets/{id}',
            path: {
                'id': id,
            },
            headers: {
                'Authorization': authorization,
            },
            errors: {
                404: `Not Found. Bucket not found`,
            },
        });
    }
}
