/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Job } from '../models/Job';
import type { ResponseMessage } from '../models/ResponseMessage';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class JobsService {
    /**
     * Get jobs
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @returns Job Job
     * @throws ApiError
     */
    public static getJobs(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<Array<Job>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/jobs',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
    /**
     * Create job
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @returns ResponseMessage Job
     * @throws ApiError
     */
    public static postJobs(
        authorization: string,
        requestBody: Job,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/jobs',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete job
     * @param jobId Job Id
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId Org Id
     * @param subOrgId Suborg Id
     * @returns ResponseMessage Job
     * @throws ApiError
     */
    public static deleteJobs(
        jobId: string,
        authorization: string,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<ResponseMessage> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/jobs/{job_id}',
            path: {
                'job_id': jobId,
            },
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
        });
    }
}
