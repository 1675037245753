/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Flag } from '../models/Flag';
import type { MessageResponse } from '../models/MessageResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class FeaturesService {
    /**
     * List features of organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @param countRollouts Count rollouts
     * @returns Flag List of features of the organization
     * @throws ApiError
     */
    public static getFeatures(
        authorization: string,
        orgId?: string,
        subOrgId?: string,
        countRollouts?: boolean,
    ): CancelablePromise<Array<Flag>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/features',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
                'count_rollouts': countRollouts,
            },
            errors: {
                400: `Bad Request`,
                404: `Organization not found`,
            },
        });
    }
    /**
     * Remove features from an organization
     * Removes specified features from an organization
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Feature to remove
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @returns any Features removed successfully
     * @throws ApiError
     */
    public static removeFeatures(
        authorization: string,
        requestBody: {
            uuid?: string;
        },
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<{
        message?: string;
    }> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/features',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
            },
        });
    }
    /**
     * Add features to an organization
     * This endpoint allows you to add features to a specified organization.
     * @param authorization The authorization token obtained from the login response. The value should be the IdToken.
     * @param requestBody Feature to add to the organization
     * @param orgId ID of the organization
     * @param subOrgId Sub-Organizations ID
     * @returns MessageResponse Features added successfully.
     * @throws ApiError
     */
    public static addFeatures(
        authorization: string,
        requestBody: Flag,
        orgId?: string,
        subOrgId?: string,
    ): CancelablePromise<MessageResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/features',
            headers: {
                'Authorization': authorization,
            },
            query: {
                'org_id': orgId,
                'sub_org_id': subOrgId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request. The request could not be understood or was missing required parameters.`,
            },
        });
    }
}
